@charset "UTF-8";
/* URL du logo à modifier */
/* Variables diverses à modifier en fonction du site */
/* Design login */
html {
  background: none; }

body {
  text-align: center; }

#login {
  padding-top: 20px; }

.login {
  position: relative;
  background: #f6faff; }
  .login h1 a {
    background: url("../../assets/img/logo_USA.svg") no-repeat center center/contain !important;
    width: 100%; }
    .login h1 a:focus {
      box-shadow: none; }
  .login a {
    padding-top: 25px;
    padding-bottom: 25px;
    color: #003470 !important;
    font-size: 14px;
    font-weight: 500; }
    .login a:hover {
      color: #1ef1bd !important; }
  .login form {
    border: 0;
    border-radius: 0;
    box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.1); }
    .login form input,
    .login form select,
    .login form textarea {
      border: 1px solid #003470;
      border-radius: 0;
      box-shadow: none; }
      .login form input:focus,
      .login form select:focus,
      .login form textarea:focus {
        border: 1px solid #004ca3;
        border-radius: 1px solid #004ca3;
        box-shadow: none; }
  .login .button.button-large {
    padding: 0 0 40px;
    width: 100%;
    height: 30px;
    line-height: 40px; }
  .login .button-primary {
    transition: ease .5s;
    margin-top: 50px;
    border: 1px solid #cdd4db;
    box-shadow: none;
    background: #cdd4db;
    padding: 15px;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: none;
    letter-spacing: 2px;
    color: #003470;
    font-weight: 500; }
    .login .button-primary:hover, .login .button-primary:active, .login .button-primary:focus {
      transition: ease .5s;
      border: 1px solid #e72e37;
      box-shadow: none;
      background-color: #e72e37;
      text-decoration: none;
      color: #fff; }
  .login .message {
    border-left: 4px solid #1ef1bd; }
